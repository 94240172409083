import axios from 'axios';
import type { HeadersDefaults } from 'axios';
import { API_URL } from '@/configs';
import AuthService from '@/services/auth.service';

// Check if 'isPdfProjectRunningInDocker' exists in the localStorage. This  is set when the PDF project is launched from the Docker container.
// If the isPdfProjectRunningInDocker is true, replace 'localhost' with 'host.docker.internal' in the baseURL.
const http = axios.create({
  baseURL: window.localStorage.getItem('isPdfProjectRunningInDocker') ? API_URL.replace('localhost', 'host.docker.internal') : API_URL,
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401 && error?.response?.data?.error == 'revoked token') {
      AuthService.clearCache();
      return error;
    }

    const errMessage: string = error?.response?.data || 'Unknown Error';
    return Promise.reject(errMessage);
  },
);

const setHTTPHeader = (header: HeadersDefaults['common']) => {
  http.defaults.headers.common = { ...http.defaults.headers.common, ...header };
};

export { http, setHTTPHeader };
