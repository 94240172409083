import { defineStore } from 'pinia';
import UserAPI from '@/apis/user.api';

export const useUserStore = defineStore({
  id: 'user-store',
  state: () => ({
    isImpersonating: false,
    currentUser: null,
    user: null,
    users: [],
    onlyActiveUsers: [],
    search: '',
    loading: true,
    pagination: {},
    page: 1,
    onlyActiveUsersPagination: {},
  }),
  getters: {},
  actions: {
    async fetchUsers(page = 1, search = '') {
      this.loading = true;
      const res = await UserAPI.fetchUsers(page, search);
      this.users = res?.users;
      this.pagination = {
        current_page: res.meta.pagy.page,
        total_pages: res.meta.pagy.pages,
        total_items: res.meta.total_count,
      };
      this.loading = false;
    },
    async fetchOnlyActiveUsers(page = 1, search = '') {
      this.loading = true;
      const res = await UserAPI.fetchOnlyActiveUsers(page, search);
      this.onlyActiveUsers = res?.users;
      this.onlyActiveUsersPagination = {
        current_page: res.meta.pagy.page,
        total_pages: res.meta.pagy.pages,
        total_items: res.meta.total_count,
      };
      this.loading = false;
    },
    async searchUsers(page = 1, search = '') {
      const res = await UserAPI.fetchUsers(page, search);
      return res.users;
    },
    async searchOnlyActiveUsers(page = 1, search = '') {
      const res = await UserAPI.fetchOnlyActiveUsers(page, search);
      return res.users;
    },
    async fetchUser(id: number, search = '') {
      await this.resetStates();
      const res = await UserAPI.fetchUser(id, search);
      this.user = res.user;
      this.loading = false;
    },
    async fetchCurrentUser(id: number) {
      await this.resetStates();
      const res = await UserAPI.fetchUser(id);
      localStorage.setItem('user', JSON.stringify(res.user));
      this.currentUser = res.user;
    },
    async updateUser(id: number, data: {}) {
      const res = await UserAPI.updateUser(id, data);
      if (Number(id) === Number(this.currentUser.id)) {
        this.currentUser = res.user;
        localStorage.setItem('user', JSON.stringify(res.user));
      } else {
        this.user = res.user;
      }
    },
    async updateUserEvents(id: number, data: {}) {
      await UserAPI.updateUser(id, data);
    },
    async impersonateUser(id: number) {
      const res = await UserAPI.impersonateUser(id);
      this.user = res?.user;
    },
    async stopImpersonating() {
      const res = await UserAPI.stopImpersonating();
      this.user = res?.user;
    },
    async resetStates() {
      this.user = null;
      this.users = [];
      this.page = 1
      this.search = ''
      this.onlyActiveUsers = [];
      this.pagination = {};
      this.onlyActiveUsersPagination = {};
      this.loading = true;
    },
    adminOrAbove() {
      if (localStorage.getItem('user')) {
        const access_level = JSON.parse(localStorage.getItem('user'))?.access_level;
        return ['admin', 'super_admin'].includes(access_level);
      }
    },
    isSuperAdmin() {
      if (localStorage.getItem('user')) {
        const access_level = JSON.parse(localStorage.getItem('user'))?.access_level;
        return ['super_admin'].includes(access_level);
      }
    },
    async isSomeoneImpersonating() {
      const data = await UserAPI.isImpersonating()
      this.isImpersonating = data
    },
  },
});
