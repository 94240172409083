import { Component, createApp } from 'vue/dist/vue.esm-bundler';
import { Router } from 'vue-router';
import { VueQueryPlugin } from 'vue-query';
import { globalProperties } from './globalProperties';
import { pinia } from '@/stores';
import { setHTTPHeader } from '@/services/http.service';
import AuthService from '@/services/auth.service';
import vuetify from "@/plugins/vuetify";
import SvnUiLibrary from "svn-ui-library/components";
import "svn-ui-library/style.css";
// import "../assets/css/style.css";
import vue3GoogleLogin from "vue3-google-login";
import { GOOGLE_CLIENT_ID } from '@/configs';
import { Intersect } from "vuetify/directives"

const token = AuthService.getToken();

if (token) {
  setHTTPHeader({ Authorization: token });
}

export const setupEntryPoint = async (rootComponent: Component, router: Router) => {
const app = createApp(rootComponent);
  const webFontLoader = await import(/* webpackChunkName: "webfontloader" */'webfontloader')

  webFontLoader.load({
    google: {
      families: ['Work Sans:100,200,300,400,500,600,700,800,900&display=swap'],
    },
  })

  app.directive("intersect", Intersect);

  app.use(vuetify);
  app.use(SvnUiLibrary);
  app.use(router);
  app.use(pinia);
  app.use(VueQueryPlugin);
  app.use(vue3GoogleLogin, {
    clientId: GOOGLE_CLIENT_ID,
  });
  app.config.globalProperties = { ...app.config.globalProperties, ...globalProperties };

  app.mount('#app');
};
